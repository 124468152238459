body {
  background-color: #dedede;
  color: rgb(33, 33, 33);
  margin: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

td {
  text-align: right !important;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

table {
  width: 100%;
}

.detailedChart {
  margin-top: -12px;
  border-collapse: collapse;
  border: 1px solid #0000007b;
}

.detailedChart td {
  border: 1px solid #00000089;
}

.bar {
  width: 700px !important;
  height: 350px !important;
  margin: 0 auto !important;
  padding: 0px !important;
}

@media only screen and (max-width: 900px) and (orientation: landscape) {
  .bar {
    width: 95% !important;
    height: 70vh !important;
  }
}

@media only screen and (max-width: 900px) and (orientation: portrait) {
  .bar {
    width: 95% !important;
    height: 45% !important;
  }
}

@media only screen and (max-width: 600px) and (orientation: portrait) {
  table {
    font-size: x-small;
  }
}

.errorMessage {
  font-weight: bold;
  color: rgb(0, 0, 0);
  padding: 10px;
  margin-top: 10px;
  border-radius: 10px;
  box-shadow: 5px 5px 10px 5px rgba(0, 0, 0, 0.241);
  background-color: #d10c0c9b;
}

.loader {
  font-weight: bold;
  color: rgb(0, 0, 0);
  padding: 5px;
  margin-top: 10px;
  border-radius: 6px;
  box-shadow: 5px 5px 10px 5px rgba(0, 0, 0, 0.241);
  background-color: #0adfd89b;
}
